import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'

import Image from '../components/image'
import { 
  components, 
  ModalPageWrapper, 
  H1, 
  H2, 
  H4, 
  P,
  CloseBtn, 
  Top, 
  Intro, 
  MetaContent,
  ImageWrapper,
} from "./styles"

const Layout = ({ children, pageContext }) => {
  const { 
    role,
    deliverables,
    article,
    title,
    description,
    img,
  } = pageContext.frontmatter
  return (
    <ModalRoutingContext.Consumer>
    {({ closeTo }) => (
      <>
        <CloseBtn to={closeTo} state={{noScroll: true}}/>
        <ModalPageWrapper isArticle={article}>
          <div>
            <H1>{title}</H1>
            <Top>
              <Intro>
                <H2>Sobre el cliente</H2>
                <P>{description}</P>
              </Intro>
              {role && deliverables && (
              <MetaContent>
                {role && (
                  <div>
                    <H4>Rol</H4>
                    {role.map((r, i) => (
                      <P key={i}>{r}</P>
                    ))}
                  </div>
                )}
                {deliverables && (
                  <div>
                    <H4>Entregables</H4>
                    {deliverables.map((d, i) => (
                      <P key={i}>{d}</P>
                    ))}
                  </div>
                )}
              </MetaContent>
              )}
            </Top>
          </div>
          <ImageWrapper isArticle={article}>
            <Image imgName={img} />
          </ImageWrapper>
          <div>
            <MDXProvider components={components}>{children}</MDXProvider>
          </div>
        </ModalPageWrapper>
      </>
    )}
    </ModalRoutingContext.Consumer>
  )
}

export default Layout