import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`The JAM`}</h1>
    <p>{`JAMstack son las siglas de Javascript, API y Markup.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Javascript`}</em></strong>{` maneja las funcionalidades dinámicas. Se puede usar vanilla javascript o cualquier framework, React, Vue, Angular.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`APIs`}</em></strong>{`, las acciones que normalmente se manejan en un servidor backend ahora se resumen en API's reutilizables, a las que se accede a través de conexiones seguras HTTPS.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Markup`}</em></strong>{`, archivos HTML estáticos. Pre-renderizado al momento de la compilación.`}</p>
    <h2>{`Cuando tu sitio/app `}<em parentName="h2">{`no`}</em>{` está construido con la arquitectura JAMstack?`}</h2>
    <p>{`Sitios o aplicaciones que se basan en una relación directa entre el cliente y el servidor como WordPress, Drupal, Joomla o Squarespace.`}</p>
    <p>{`In general any app that needs a server to run or it relies on a backend language it's not considered as a JAMstack app.`}</p>
    <p>{`En general, cualquier aplicación que necesite un servidor para ejecutarse o que dependa de un lenguaje backend no se considera una aplicación JAMstack.`}</p>
    <h2>{`Porque JAMstack?`}</h2>
    <p><strong parentName="p"><em parentName="strong">{`Mejor Performance:`}</em></strong>{` Sin una base de datos, no hay consultas múltiples que consuman mucho tiempo de ejecución. App's estáticas son más rápidas, hasta 10 veces más rápidas, el contenido se genera en el despliege de la app.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Mayor seguridad:`}</em></strong>{` Static websites sin plugins o bases de datos, con los procesos separados mediante API's, las áreas de ataque se reducen, mayor seguridad significa menos costos de mantenimiento.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Mejor experiencia de Desarrollador:`}</em></strong>{` Desarrollo enfocado para front-ends debido a que eliminamos la necesidad de mantener un stack completo de back-end.`}</p>
    <p>{`Por definición, `}<strong parentName="p">{`JAMstack`}</strong>{` es un enfoque alternativo para crear sitios web. Para los clientes, significa sitios web más rápidos con mejores capacidades y calificaciones SEO, en general, usuarios más felices :) .`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      