import React, { useState, useEffect } from 'react'

import { ListItem, MobileNavWrapper, Nav, Ul, Container, Icon, Available } from './styles'
import Toggle from "../Toggle"

const MenuItem = ({children, href}) => (
  <ListItem>
    <a href={href}>{children}</a> 
  </ListItem>
)

const MobileMenu = () => {
  const [ menuShowed, setMenuShowed ] = useState(false)
  const [ time, setTime ] = useState(null)
  console.log(menuShowed)
  useEffect(() => {
    let current = new Date()
    setTime(current.getHours() + ":" + current.getMinutes())
  }, [])
  return (
    <>
      <Toggle 
        menuShowed={menuShowed}
        setMenuShowed={setMenuShowed}
      />
      <MobileNavWrapper menuOpened={menuShowed} className="test">
        <Nav>
          <Ul className={menuShowed ? "showNav--main" : ""}>
            <span>Contáctame</span>
            <MenuItem href="mailto:adinjesuha@gmail.com" target="_blank">adinjesuha@gmail.com</MenuItem>
            <MenuItem href="tel:504-9579-8520">(504) 9579-8520</MenuItem>
          </Ul>
          <Container className={menuShowed ? "showNav--alter" : ""}>
            <span>Current Location:</span>
            <span>
              <Icon />
              Tegucigalpa, Hn {time} CST
            </span>
            <Available>
              <span />
              <span>Disponible para Freelance (En oficina hasta las 6)</span>
            </Available>
          </Container>
        </Nav>
      </MobileNavWrapper>
    </>
  )
}

export default MobileMenu