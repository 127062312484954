import styled from 'styled-components'
import { device } from '../utils/breakpoints'

export const Container = styled.div`
  padding: 20px;
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
  position: relative;
  @media ${device.laptopL}{
    padding-left: 80px;
    padding-right: 80px;
  }
`
