import React from 'react'

import { Container } from '../globals'
import { StackWrapper, StackBlock, StackBlockGrid, IconsWrapper } from './styles'
import { Gatsby, Next, Jekyll, Contentful, Prismic, Netlify, Vercel, GoogleCloud, GraphCMS, Fauna } from '../icons'

const Stack = () => {
  return (
    <StackWrapper>
      <Container>
        <span className="eyebrow">Cool tech</span>
        <div className="stack-section-title">Construyendo la Web Moderna</div>
        <div className="stack-section-message">
          <p>Desarrollo <strong>Front-End</strong> sobre una variedad de plataformas modernas, basadas en los requerimientos técnicos del proyecto, para ofrecer el mejor rendimiento posible.</p>
        </div>
        <StackBlockGrid>
          <StackBlock>
            <div>
              <h3>Build / Front-end Frameworks</h3>
              <IconsWrapper>
                <a href="https://www.gatsbyjs.com/" target="_blank" rel="noopener noreferrer" aria-label="icon"><Gatsby /></a>
                <a href="https://nextjs.org/" target="_blank" rel="noopener noreferrer" aria-label="icon"><Next /></a>
                <a href="https://jekyllrb.com/" target="_blank" rel="noopener noreferrer" aria-label="icon"><Jekyll /></a>
              </IconsWrapper>
            </div>
          </StackBlock>
          <StackBlock>
            <div>
              <h3>Headless CMS</h3>
              <IconsWrapper>
                <a href="https://www.contentful.com/" target="_blank" rel="noopener noreferrer" aria-label="icon"><Contentful /></a>
                <a href="https://prismic.io/" target="_blank" rel="noopener noreferrer" aria-label="icon"><Prismic /></a>
                <a href="https://graphcms.com/" target="_blank" rel="noopener noreferrer" aria-label="icon"><GraphCMS /></a>
              </IconsWrapper>
            </div>
          </StackBlock>
          <StackBlock>
            <div>
              <h3>Deploy & CDN</h3>
              <IconsWrapper>
                <a href="https://www.netlify.com/" target="_blank" rel="noopener noreferrer" aria-label="icon"><Netlify /></a>
                <a href="https://vercel.com" target="_blank" rel="noopener noreferrer" aria-label="icon"><Vercel /></a>
                <a href="https://cloud.google.com/" target="_blank" rel="noopener noreferrer" aria-label="icon"><GoogleCloud /></a>
              </IconsWrapper>
            </div>
          </StackBlock>
          <StackBlock>
            <div>
              <h3>Serverless Data Base</h3>
              <IconsWrapper>
                <a href="https://fauna.com/" target="_blank" rel="noopener noreferrer" aria-label="icon"><Fauna /></a>
              </IconsWrapper>
            </div>
          </StackBlock>
        </StackBlockGrid>
      </Container>
    </StackWrapper>
  )
}

export default Stack