import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Home from "../components/Home"
import Filters from "../components/Filters"
import Stack from "../components/Stack"

const IndexPage = ({data}) => (
  <Layout>
    <Seo title="Home" />
    <Home />
    <Filters />
    <Stack />
  </Layout>
)

export default IndexPage