import styled from 'styled-components'
import { device } from '../../utils/breakpoints'
import CloseIcon from '../../images/mobile-filter-close.svg'
import ArrowIconDown from '../../images/mobile-flter-down-arrow.svg'
import ArrowIconRight from '../../images/mobile-filter-right-arrow.svg'

export const FiltersContainerWrapper = styled.div`
  position: relative;
  margin: 50px 0 0;
  margin-bottom: -100px;
  @media ${device.laptop}{
    margin: 100px 0 0;
    margin-bottom: -150px;
    padding-bottom: 50px;
  }
`

export const FiltersMenuMobile = styled.div`
  padding: 20px 0;
  position: fixed;
  left: 20px;
  top: 20px;
  padding: 0;
  background-color: #f4f4f4;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  z-index: 100;

  > div:first-child{
    width: 40px;
    margin: 4px;

    position: absolute;
    top: 0;
    left: 0;
    opacity: ${props => props.activeFilter ? "0" : "1"};
    transform: ${props => props.activeFilter ? "scale(.5)" : "scale(1)"};
    transition: all .3s;
    z-index:10;
  }
  ul {
    max-height:90vh;
    max-width:90vw;
    overflow: ${props => props.activeFilter ? "scroll" : "hidden"};
    transition: padding .3s;
    margin: 0;
    margin-left: -10px;
    padding: ${props =>  props.activeFilter ? "0 0 20px" : "0"};
    &:after {
      content: "";
      background: #e5e5e5;
      display: block;
      position: absolute;
      top: 48px;
      left: 0;
      width: 100%;
      height: ${props => props.activeFilter ? "1px" : 0};
      transition: height .3s;
    }
    &:before {
      content: "Filtrar Contenido";
      color: #222222 !important;
      line-height: 48px;
      font-size: 16px;
      padding: 0 25px 0 65px;
      transition: all .3s;
      overflow: hidden;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      background-image: ${`url(${CloseIcon})`};
      background-repeat: no-repeat;
      background-position: 26px;
      background-size: ${props => props.activeFilter ? "18px" : "0px"};
      height: ${props => props.activeFilter ? "48px" : "0"};
      width: ${props => props.activeFilter ? "auto" : "0"};
    }
    li {
      position: relative;
      overflow: hidden;
      color: #222222;
      line-height: ${props => props.activeFilter ? "56px" : "0"};
      font-size: ${props => props.activeFilter ? "26px" : "0"};
      padding: ${props => props.activeFilter ? "0 42px 0 54px" : "0"};
      cursor: pointer;
      margin: 0 10px;
      opacity: 0.5;
      transition: all .3s;
      &:hover {
        opacity: 1;
        &:before {
          content: '';
          display: block;
          width: 62px;
          height: 56px;
          position: absolute;
          left: 0;
          top: 0;
          background-image: ${`url(${ArrowIconRight})`};
          background-repeat: no-repeat;
          background-position: 21px center;
        }
      }
    }
    li.active {
      font-size: ${props => props.activeFilter ? "26px" : "16px"};
      line-height: ${props => props.activeFilter ? "56px" : "48px"};
      padding: 0 54px;
      opacity: 1;
      background-image:${props => props.activeFilter ? 'none' : `url(${ArrowIconDown})`}; 
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) center;
      &:before {
        content: '';
        display: block;
        width: 62px;
        height: 56px;
        position: absolute;
        left: 0;
        top: 0;
        background-image: ${`url(${ArrowIconRight})`};
        background-repeat: no-repeat;
        background-position: 21px center;
      }
    }
  }
  @media (min-width: 864px){
    display: none;
  }
`

export const FiltersMenu = styled.div`
  display: none;
  @media (min-width: 864px){
    padding: 20px 0;
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    background-color: ${props => props.showOnScrollTop ? 'rgba(0,0,0,0.9)' : 'rgba(0,0,0,0)'};
    transition: background-color .2s,;
    display: flex;
    align-items: center;
    .container {
      margin: 0 auto;
      width: 100%;
      max-width: 1350px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      overflow: hidden;
    } 
    .avatar{
      position: absolute;
      left: 20px;
      opacity: ${props => props.showOnScrollTop ? 1 : 0};
      transform: ${props => props.showOnScrollTop ? `scale(1)` : `scale(0)`};
      transition: ${props => props.showOnScrollTop ? `transform .3s, opacity .3s .1s` : `transform .3s, opacity .3s 0s`};
    }
    .logo{
      position: absolute;
      left: 70px;
      overflow: hidden;
      opacity: ${props => props.showOnScrollTop ? 1 : 0};
      transition: opacity .3s .2s;
      padding-right: 15px;
      .gatsby-image-wrapper{
        transform: ${props => props.showOnScrollTop ? `translateX(0)` : `translateX(-120px)`};
        transition: transform .3s;
      }
      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4);
        position: absolute;
        top: 0;
        right: 0;
        transform: ${props => props.showOnScrollTop ? `scale(1)` : `scale(0)`};
        transition: ${props => props.showOnScrollTop ? `transform .3s .3s` : 'transform .3 0s'};
      }
    }
    .tab-menu{
      list-style: none;
      display: block;
      width: 100%;
      transform: ${props => props.showOnScrollTop ? 'translateX(180px)' : 'translateX(0)'};
      transition: ${props => props.showOnScrollTop ? 'transform .3s' : 'transform .3s .1s'};
      li {
        cursor: pointer;
        display: inline-block;
        margin: 0 10px;
        font-weight: bold;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        font-size: 12px;
        opacity: 0.7;
      }
      li.active {
        opacity: 1;
      }
    }
  }
  @media (min-width: 1200px){
    padding: 30px 0;
    .container {
      padding: 0 80px;
    } 
    .avatar{
      left: 80px;
    }
    .logo{
      left: 130px;
    }
  }
`