import styled, { keyframes }  from 'styled-components'
import { device } from '../../utils/breakpoints'

const BlinkingCursor = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const Pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(102, 255, 153, 0.4);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
`

export const Heading = styled.div`
  margin-top: 55px;
  display: flex;
  justify-content: space-between;
  position: relative;
  > div:first-child {
    margin-top: 40vh;
    max-width: 400px;
    > h1 {
      font-weight: 400;
      line-height: 1.22em;
      font-size: 1.8rem;
      .react-rotating-text-cursor {
        font-weight: lighter;
        animation: ${BlinkingCursor} 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
      }
    }
    p {
      margin-top: 25px;
      opacity: .85;
      letter-spacing: .5px;
      font-size: 16px;
      line-height: 1.5em;
    }
  }
  > div:last-child{
    display: none;
  }
  @media ${device.mobileL}{
    > div:first-child h1{
      font-size: 2rem;
    }
  }
  @media (min-width: 990px){
    > div:last-child{
      display: block;
      font-size: 22px;
      letter-spacing: .5px;
      text-align: right;
      > span:first-child, > span:last-child{
        display: block;
        opacity: .85;
        font-size: 16px;
        line-height: 2.25em;
      }
      .icon{
        display: inline-block;
        width: 20px;
        height: 16px;
        margin-right: 5px;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjk3IDI5NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjk3IDI5NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIGZpbGw9IndoaXRlIj4NCgk8Zz4NCgkJPHBhdGggZD0iTTE0OC41LDBDODcuNDMsMCwzNy43NDcsNDkuNzAzLDM3Ljc0NywxMTAuNzk3YzAsOTEuMDI2LDk5LjcyOSwxNzkuOTA1LDEwMy45NzYsMTgzLjY0NQ0KCQkJYzEuOTM2LDEuNzA1LDQuMzU2LDIuNTU5LDYuNzc3LDIuNTU5YzIuNDIxLDAsNC44NDEtMC44NTMsNi43NzgtMi41NTljNC4yNDUtMy43MzksMTAzLjk3NS05Mi42MTgsMTAzLjk3NS0xODMuNjQ1DQoJCQlDMjU5LjI1Myw0OS43MDMsMjA5LjU3LDAsMTQ4LjUsMHogTTE0OC41LDI3Mi42ODljLTIyLjA0OS0yMS4zNjYtOTAuMjQzLTkzLjAyOS05MC4yNDMtMTYxLjg5Mg0KCQkJYzAtNDkuNzg0LDQwLjQ4My05MC4yODcsOTAuMjQzLTkwLjI4N3M5MC4yNDMsNDAuNTAzLDkwLjI0Myw5MC4yODdDMjM4Ljc0MywxNzkuNjU5LDE3MC41NDksMjUxLjMyMiwxNDguNSwyNzIuNjg5eiIvPg0KCQk8cGF0aCBkPSJNMTQ4LjUsNTkuMTgzYy0yOC4yNzMsMC01MS4yNzQsMjMuMTU0LTUxLjI3NCw1MS42MTRjMCwyOC40NjEsMjMuMDAxLDUxLjYxNCw1MS4yNzQsNTEuNjE0DQoJCQljMjguMjczLDAsNTEuMjc0LTIzLjE1Myw1MS4yNzQtNTEuNjE0QzE5OS43NzQsODIuMzM3LDE3Ni43NzMsNTkuMTgzLDE0OC41LDU5LjE4M3ogTTE0OC41LDE0MS45MDENCgkJCWMtMTYuOTY0LDAtMzAuNzY1LTEzLjk1My0zMC43NjUtMzEuMTA0YzAtMTcuMTUsMTMuODAxLTMxLjEwNCwzMC43NjUtMzEuMTA0YzE2Ljk2NCwwLDMwLjc2NSwxMy45NTMsMzAuNzY1LDMxLjEwNA0KCQkJQzE3OS4yNjUsMTI3Ljk0OCwxNjUuNDY0LDE0MS45MDEsMTQ4LjUsMTQxLjkwMXoiLz4NCgk8L2c+DQo8L3N2Zz4NCg==);
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
  @media ${device.tablet}{
    > div:first-child {
      margin-top: 25vh;
      max-width: 550px;
      h1 {
        font-size: 2.4rem;
      }
    }
  }
  @media ${device.laptop}{
    > div:first-child{
      margin-top: 0;
      max-width: 600px;
      h1 {
        font-size: 3rem;
        width: 660px;
      }
    }
  }
`

export const ContactInfo = styled.div`
  display: none;
  @media (min-width: 990px){
    display: block;
    margin-top: 40px;
    > a:first-child{
      text-decoration: none;
      color: var(--bg-color);
      background: var(--text-color);
      display: inline-block;
      border-radius: 25px;
      padding: 0 30px;
      line-height: 50px;
      font-weight: 500;
      margin-right: 30px;
      transition: background .3s,color .3s;
      &:hover{
        background: var(--bg-color);
        color: var(--text-color);
      }
    }
    > a:last-child{
      display: inline-block;
      line-height: 50px;
      color: var(--text-color);
      text-decoration: none;
    }
  }
`

export const Availability = styled.div`
  display: none;
  @media (min-width: 990px){
    display: block;
    margin-top: 30px;
    font-size: 12px;
    position: relative;
    span{
      display: inline-block;
      padding-left: 15px;
      opacity: .85;
      &:before{
        content: '';
        background: #66FF99;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 10px;
        animation: ${Pulse} 2s infinite;
        position: absolute;
        left: 0;
        top: 2px;
      }
    }
  }
`