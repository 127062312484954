import React from 'react'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import Footer from "./Footer"
import BackgroundImage from "./BackgroundImage"
import BackgroundVideo from './BackgroundVideo'
import MobileMenu from './MobileMenu'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <MobileMenu />
      <main>
        {children}
      </main>
      <Footer />
      <BackgroundImage />
      <BackgroundVideo />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
