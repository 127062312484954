import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Image from '../image'
import Results from './Results'
import { FiltersContainerWrapper, FiltersMenu, FiltersMenuMobile } from './styles'

const FiltersContainer = (props) => {
  const data = useStaticQuery(graphql`
    query worksQuery {
      allMdx(
        sort: {
          fields: frontmatter___order, 
          order: ASC
        }
      ){
        nodes {
          frontmatter {
            title
            path
            category
            deliverables
            description
            img
            role
            externalLink
          }
        }
      }
    }
  `)
  
  const [currentFilter, setCurrentFilter] = useState('destacado')
  const [activeFilter, setActiveFilter] = useState(false)
  const [showOnScrollTop, setShowOnScrollTop] = useState(false)
  const filterNavRef = useRef(null)

  useEffect(() => {
    function handleAppear() {
      if ( filterNavRef?.current.getBoundingClientRect().top === 0){
        setShowOnScrollTop(true)
      } else {
        setShowOnScrollTop(false)
      }
    }
    window.addEventListener("scroll", handleAppear)
    return () => {
      window.removeEventListener("scroll", handleAppear)
    }
  }, [])

  const { nodes } = data.allMdx

  let filteredWorks

  if(currentFilter === "all"){
    filteredWorks = nodes
  }else {
    filteredWorks = nodes.filter( node => {
      const filtered = node.frontmatter.category.includes(currentFilter)
      return filtered
    })
  }

  const handleChange = (item) => {
    setTimeout(() => {
      setCurrentFilter(item)
    }, 1000)
  }

  const tabItems = [
    {
      label: 'Destacado', 
      value: 'destacado'
    },
    {
      label: 'E-commerce', 
      value: 'ecommerce'
    },
    {
      label: 'Web Site', 
      value: 'web-design'
    },
    {
      label: 'Web App', 
      value: 'web-app'
    },
    {
      label: 'Ver Todo', 
      value: 'all'
    },
  ]

  return (
    <FiltersContainerWrapper>
      <FiltersMenuMobile 
        activeFilter={activeFilter}
        onClick={() => setActiveFilter(!activeFilter)}
      >
        <div>
          <Image imgName="avatar.png"/>
        </div>
        <ul>
        {tabItems.map((item, i) => (
          <li
            className={item.value === currentFilter ? 'active' : ''}
            key={i} 
            onClick={() => handleChange(item.value)}
          >
            {item.label}
          </li>
        ))}
        </ul>
      </FiltersMenuMobile>
      <FiltersMenu ref={filterNavRef} showOnScrollTop={showOnScrollTop}>
        <div className="container">
          <div className="avatar">
            <Image imgName="avatar.png"/>
          </div>
          <div className="logo">
            <Image imgName="adin-jesuha.png"/>
          </div>
          <ul className="tab-menu">
            {tabItems.map((item, i) => (
              <li
                className={item.value === currentFilter ? 'active' : ''}
                key={i} 
                onClick={() => {
                  setCurrentFilter(item.value)
                  setActiveFilter(false)
                }}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </FiltersMenu>
      <Results works={filteredWorks} />
    </FiltersContainerWrapper>
  )
}

export default FiltersContainer