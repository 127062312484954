import React from 'react'
import Masonry from 'react-masonry-component';

import { Container } from '../../globals'
import { ResultsWrapper } from './styles'
import Post from '../../Post'

const Results = ({works}) => (
  <ResultsWrapper>
    <Container>
      <Masonry>
      {works.map((work, i) => (
        <Post key={i} work={work} />
      ))}
      </Masonry>
    </Container>
  </ResultsWrapper>
)

export default Results