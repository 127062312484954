import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Project Overview`}</h1>
    <p>{`El sitio web de FENAGH fue desarrollado utilizando la arquitectura JAMstack, una forma moderna de crear sitios web y aplicaciones que ofrece un mejor rendimiento.`}</p>
    <p>{`Al desarrollar sitios web con la arquitectura JAMstack, se obtienen multiples beneficios, mejor performance, mayor seguridad, facil de escalar y una mejor experiencia de desarrollo.`}</p>
    <h2>{`Gatsby para el desarrollo Front-end`}</h2>
    <p>{`Porque `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/"
      }}>{`Gatsby`}</a>{`? Gatsby es un framework de JavaScript que ofrece multiples beneficios a los desarrolladores Front-end, optimización de imágenes, carga en línea de estilos CSS criticos, lazy-loading, prefetching o code splitting por hablar de algunos de sus beneficios.`}</p>
    <h2>{`Contentful para la gestión de contenidos (CMS)`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.contentful.com/"
      }}>{`Contentful`}</a>{` es una herramienta CMS para producir, administrar y publicar contenidos de forma sencilla en cualquier web. Basada en la nube, permite a cualquier usuario, ya sean desarrolladores o editores de contenido, trabajar unidos para hacer sus tareas más fáciles y ágiles. `}</p>
    <h2>{`Despliegue continuo con Netlify`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.netlify.com/"
      }}>{`Netlify`}</a>{` es una plataforma que nace para automatizar proyectos webs. Aúna las tareas de integración continua y despliegue de infraestructura web en un solo flujo de ejecución.`}</p>
    <br />
    <br />
    <center>
  <a href="https://www.fenagh.net/" target="_blank" rel="noopener">IR A LA WEB</a>
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      