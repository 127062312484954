import React from 'react'

import { Container } from '../globals'
import { FooterWrapper } from './styles'

const Footer = () => {
	return (
		<FooterWrapper>
			<Container>
				<div className="footer-availability"> 
					<span />
					<span>Disponible para Freelance</span>
				</div>
				<div className="footer-message">
					<p>Tienes un proyecto en mente y necesitas de un profesional independiente para llevarlo a cabo — hagámoslo!</p>
				</div>
				<div className="footer-contact-info">
					<a className="footer-mailto-link" href="mailto:adinjesuha@gmail.com" target="_blank" rel="noreferrer">adinjesuha@gmail.com</a>
					<a href="tel:(504) 9579-8529" className="footer-phone-text-link">text (504) 9579-8529</a>
				</div>
			</Container>
		</FooterWrapper>
	)
}

export default Footer