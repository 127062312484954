import React, { useState, useEffect } from 'react'
import ReactRotatingText from 'react-rotating-text'

import { Container } from '../globals'
import { Heading, ContactInfo, Availability } from './styles'

const Home = () => {
  const [ time, setTime ] = useState(null)
  
  useEffect(() => {
    let current = new Date()
    setTime(current.getHours() + ":" + current.getMinutes())
  }, [])

  return (
    <Container>
      <Heading>
        <div>
          <h1>
            Desarrollando websites / apps para {" "}
            <ReactRotatingText items={['Grandes Empresas.', 'Negocios Locales.', 'Startups.']}/>
          </h1>
          <p>Ayudo a las empresas a simplificar sus experiencias digitales mediante la creación de productos con un alto nivel de rendimiento, aplicaciones web progresivas y serverless.</p>
        </div>
        <div>
          <span>Ubicación Actual:</span>
          <span><span className="icon"/>Tegucigalpa, Hn {" "}</span>
          <span>{time} CST</span>
          <span />
          {/* <span>(in office till 6)</span> */}
        </div>
      </Heading>
      <ContactInfo>
        <a href="mailto:adinjesuha@gmail.com" target="_blank" rel="noreferrer">adinjesuha@gmail.com</a>
        <a href="tel:(504) 9579-8520">Text (504) 9579-8520</a>
      </ContactInfo>
      <Availability>
        <span>Disponiblle para freelance o consultorias / remote work o presencial</span>
      </Availability>
    </Container>
  )
}

export default Home