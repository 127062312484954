// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-works-agrobiotek-capacitaciones-mdx": () => import("./../../../src/works/agrobiotek-capacitaciones.mdx" /* webpackChunkName: "component---src-works-agrobiotek-capacitaciones-mdx" */),
  "component---src-works-agrobiotek-internacional-mdx": () => import("./../../../src/works/agrobiotek-internacional.mdx" /* webpackChunkName: "component---src-works-agrobiotek-internacional-mdx" */),
  "component---src-works-blog-template-mdx": () => import("./../../../src/works/blog-template.mdx" /* webpackChunkName: "component---src-works-blog-template-mdx" */),
  "component---src-works-camara-hondurena-de-la-leche-mdx": () => import("./../../../src/works/camara-hondurena-de-la-leche.mdx" /* webpackChunkName: "component---src-works-camara-hondurena-de-la-leche-mdx" */),
  "component---src-works-dashboard-prototype-mdx": () => import("./../../../src/works/dashboard-prototype.mdx" /* webpackChunkName: "component---src-works-dashboard-prototype-mdx" */),
  "component---src-works-faunadb-serverles-todo-mdx": () => import("./../../../src/works/faunadb-serverles-todo.mdx" /* webpackChunkName: "component---src-works-faunadb-serverles-todo-mdx" */),
  "component---src-works-fenagh-mdx": () => import("./../../../src/works/fenagh.mdx" /* webpackChunkName: "component---src-works-fenagh-mdx" */),
  "component---src-works-harrytattoosupply-mdx": () => import("./../../../src/works/harrytattoosupply.mdx" /* webpackChunkName: "component---src-works-harrytattoosupply-mdx" */),
  "component---src-works-jamstack-ecommerce-mdx": () => import("./../../../src/works/jamstack-ecommerce.mdx" /* webpackChunkName: "component---src-works-jamstack-ecommerce-mdx" */),
  "component---src-works-jamstack-mdx": () => import("./../../../src/works/jamstack.mdx" /* webpackChunkName: "component---src-works-jamstack-mdx" */),
  "component---src-works-pets-club-catalogo-mdx": () => import("./../../../src/works/pets-club-catalogo.mdx" /* webpackChunkName: "component---src-works-pets-club-catalogo-mdx" */),
  "component---src-works-pets-club-mdx": () => import("./../../../src/works/pets-club.mdx" /* webpackChunkName: "component---src-works-pets-club-mdx" */),
  "component---src-works-registro-genealogico-mdx": () => import("./../../../src/works/registro-genealogico.mdx" /* webpackChunkName: "component---src-works-registro-genealogico-mdx" */)
}

