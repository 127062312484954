import styled, { keyframes } from 'styled-components'

const Pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(102, 255, 153, 0.4);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
`

export const ListItem = styled.li`
  a {
    text-decoration: none;
    font-weight:400;
    font-size: 26px;
    line-height:1.22;
    color: var(--text-color);
    display: block;
    padding:10px 0;
    opacity:.85;
    transition:opacity .3s;
    &:hover{
      opacity: 1;
    }
  }
`

export const MobileNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color:#111517;
  height:100%;
  width:100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  visibility: ${props => props.menuOpened ? "visible" : "hidden"};
  opacity: ${props => props.menuOpened ? "1" : "0"};
  transition: opacity .3s ease,visibility .3s ease;
  z-index: 1000;
  @media (min-width: 990px){
    display: none; 
  }
`
export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12vh 0 10vh;
  height: 100%;
  text-align: center;
`
export const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  transition: opacity .3s ease-in;
  opacity: 0;
  > span {
    display: inline-block;
    opacity: .5;
    font-size: 16px;
    padding: 10px 0;
  }
`

export const Container = styled.div`
  opacity: 0;
  transition: opacity .3s ease-in;
  & > span:first-child{
    display: block;
    opacity: .5;
    font-size: 16px;
    padding:10px 0;
  }
  & > span:last-child{
    display: block;
    font-size: 22px;
  }
`

export const Icon = styled.span`
  display: inline-block;
  width: 20px;
  height: 16px;
  margin-right: 5px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjk3IDI5NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjk3IDI5NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIGZpbGw9IndoaXRlIj4NCgk8Zz4NCgkJPHBhdGggZD0iTTE0OC41LDBDODcuNDMsMCwzNy43NDcsNDkuNzAzLDM3Ljc0NywxMTAuNzk3YzAsOTEuMDI2LDk5LjcyOSwxNzkuOTA1LDEwMy45NzYsMTgzLjY0NQ0KCQkJYzEuOTM2LDEuNzA1LDQuMzU2LDIuNTU5LDYuNzc3LDIuNTU5YzIuNDIxLDAsNC44NDEtMC44NTMsNi43NzgtMi41NTljNC4yNDUtMy43MzksMTAzLjk3NS05Mi42MTgsMTAzLjk3NS0xODMuNjQ1DQoJCQlDMjU5LjI1Myw0OS43MDMsMjA5LjU3LDAsMTQ4LjUsMHogTTE0OC41LDI3Mi42ODljLTIyLjA0OS0yMS4zNjYtOTAuMjQzLTkzLjAyOS05MC4yNDMtMTYxLjg5Mg0KCQkJYzAtNDkuNzg0LDQwLjQ4My05MC4yODcsOTAuMjQzLTkwLjI4N3M5MC4yNDMsNDAuNTAzLDkwLjI0Myw5MC4yODdDMjM4Ljc0MywxNzkuNjU5LDE3MC41NDksMjUxLjMyMiwxNDguNSwyNzIuNjg5eiIvPg0KCQk8cGF0aCBkPSJNMTQ4LjUsNTkuMTgzYy0yOC4yNzMsMC01MS4yNzQsMjMuMTU0LTUxLjI3NCw1MS42MTRjMCwyOC40NjEsMjMuMDAxLDUxLjYxNCw1MS4yNzQsNTEuNjE0DQoJCQljMjguMjczLDAsNTEuMjc0LTIzLjE1Myw1MS4yNzQtNTEuNjE0QzE5OS43NzQsODIuMzM3LDE3Ni43NzMsNTkuMTgzLDE0OC41LDU5LjE4M3ogTTE0OC41LDE0MS45MDENCgkJCWMtMTYuOTY0LDAtMzAuNzY1LTEzLjk1My0zMC43NjUtMzEuMTA0YzAtMTcuMTUsMTMuODAxLTMxLjEwNCwzMC43NjUtMzEuMTA0YzE2Ljk2NCwwLDMwLjc2NSwxMy45NTMsMzAuNzY1LDMxLjEwNA0KCQkJQzE3OS4yNjUsMTI3Ljk0OCwxNjUuNDY0LDE0MS45MDEsMTQ4LjUsMTQxLjkwMXoiLz4NCgk8L2c+DQo8L3N2Zz4NCg==);
  background-repeat: no-repeat;
  background-position: center center;
`

export const Available = styled.div`
  position: relative;
  max-width: 420px;
  margin: 0 auto;
  > span:first-child{
    display: inline-block;
    padding-left: 15px;
    opacity: .85;
    &:before{
      content: '';
      background: #66FF99;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 10px;
      animation: ${Pulse} 2s infinite;
      position: absolute;
      left: 16px;
      top: 16px;
    }
  }
  > span {
    display: inline-block;
    opacity: .5;
    font-size: 16px;
    padding: 10px 0;
  }
`