import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Image = ({ imgName, className }) => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          gatsbyImageData(
            webpOptions: {quality: 100}, 
            formats: WEBP
          )
          fluid{
            originalName
          }
        }
      }
    }
  `)

  const image = data.allImageSharp.nodes.find(
    node => node.fluid.originalName === imgName
  )
  if (!image) {
    return null
  }


  return (
    <GatsbyImage
      className={className}
      image={image.gatsbyImageData}
      style={{ position: "relative", overflow: "hidden" }}
      objectFit="cover"
      objectPosition="50% 50%"
      alt={imgName}
    />
  )
}

export default Image
