import React, { useEffect, useState } from 'react'
import Image from '../image'
import { BackgroundHeaderWrapper } from './styles'

const BackgroundImage = () => {
  const [ isOpacity, setIsOpacity ] = useState(false)

  useEffect(() => {
    function handleAppear() {
      let scrollPosition = window.pageYOffset
      if ( scrollPosition > 200){
        setIsOpacity(true)
      } else {
        setIsOpacity(false)
      }
    }
    window.addEventListener("scroll", handleAppear)
    return () => {
      window.removeEventListener("scroll", handleAppear)
    }
  }, [])

  return (
    <BackgroundHeaderWrapper isOpacity={isOpacity}>
      <div className="image-wrapper">
        <Image imgName="hero-light-1.jpg" />
      </div>
    </BackgroundHeaderWrapper>
  )
}

export default BackgroundImage