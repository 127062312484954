import styled, { keyframes }  from 'styled-components'

const Pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(102, 255, 153, 0.4);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
`

export const FooterWrapper = styled.footer`
  padding-top: 2rem;
  padding-bottom: 2rem;
  .footer-availability,
  .footer-message,
  .footer-contact-info{
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  .footer-availability {
    font-size: 1.4rem;
    margin: 0 auto;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    span:first-child{
      background: #66FF99;
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 10px;
      animation: ${Pulse} 2s infinite;
      position: absolute;
      left: -6px;
    }
    span:last-child {
      position: relative;
      display: block;
    }
  }
  .footer-message {
    margin-top: -10px;
    p{
      text-align: center;
      opacity: .85;
      letter-spacing: .5px;
      font-size: 16px;
      line-height: 1.5em;
    }
  }
  .footer-contact-info{
    margin-top: 40px;
    .footer-mailto-link{
      text-decoration: none;
      color: var(--bg-color);
      background: var(--text-color);
      border-radius: 25px;
      padding: 0 30px;
      line-height: 50px;
      font-weight: 500;
      transition: background .3s,color .3s;
      margin-right: 0;
      display: block;
      margin-bottom: 15px;
      &:hover {
        background: var(--bg-color);
        color: var(--text-color);
      }
    }
    .footer-phone-text-link{
      line-height: 50px;
      color: var(--text-color);
      text-decoration: none;
    }
  }
  @media (min-width: 600px){
    padding-top: 6rem;
    .footer-availability{
      font-size: 2rem;
      span:first-child{
        position: initial;
      }
    }
    .footer-contact-info{
      display: flex;
      align-items: center;
      justify-content: center;
      .footer-mailto-link,
      .footer-phone-text-link {
        display: inline-block;
      }
      .footer-mailto-link{
        margin-right: 35px; 
        margin-bottom: 0;
      }
    }
  }
`