import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Image from '../image'
import { HeaderWrapper } from './styles'
import { Container } from '../globals'

const Header = ({ siteTitle }) => (
  <HeaderWrapper>
    <nav>
      <Link to="/">
        {siteTitle}
        <div>
          <Image imgName="avatar.png"/>
        </div>
        <div>
          <Image imgName="adin-jesuha.png"/>
        </div>
      </Link>
    </nav>
  </HeaderWrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
