import { Link } from "gatsby";
import styled from 'styled-components'
import { device } from '../utils/breakpoints'

export const H1 = styled.h1`
  font-weight: 400;
  max-width: 90%;
  margin-bottom: 30px;
  @media ${device.tablet}{
    font-size: 2.25rem;
    margin-bottom: 40px;
  }
`

export const H2 = styled.h2`
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 1.2rem;
  @media ${device.tablet}{
    font-size: 1.5rem;
  }
`

export const H4 = styled(H2)``

export const P = styled.p`
  color:#979797;
  margin-bottom: 1rem;
  & + h2 {
    margin-top: 1.5rem;
  }
  @media ${device.tablet}{
    font-size: 1.25rem;
  }
`

export const A = styled.a`
  background: linear-gradient(to right,var(--highlight-color-1),var(--highlight-color-2));
  color: var(--text-color);
  text-decoration: none;
  font-weight: 600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const UL = styled.ul`
  margin: 0px 0 30px 25px;
  color:#979797;
  li {
    margin-bottom: 8px;
    list-style-type: disc;
  }
  @media ${device.tablet}{
    font-size: 1.25rem;
  }
`

export const Strong = styled.strong`
  font-weight: 500;
  color: #fff;
`

export const ModalPageWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  max-width: 750px;
  padding: 6vw 8vw;
  background-color: var(--bg-color);
  color: #f4f4f4;
  box-shadow: 0 0 45px rgba(0,0,0,.25);
  transition: opacity 1s;
  min-height: calc(100vh - (6vw * 2));
  box-sizing: content-box;
  position: relative;
  display: flex;
  flex-direction: column;
  > div:first-child {
    order: ${props => props.isArticle ? '2' : 'initial'};
  }
`

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.tablet}{
    flex-direction: row;
  }
`

export const Intro = styled.div`
  order: 2;
  width: 100%;
  @media ${device.tablet}{
    order: 1;
    width: calc(100% - 280px);
  }
`

export const MetaContent = styled.div`
  text-align: left;
  width: 100%;
  order: 1;
  @media ${device.tablet}{
    order: 2;
    width: 280px;
    padding-left: 30px;
    text-align: right;
  }
  > div:first-child {
    margin-bottom: 40px;
  }
`

export const ImageWrapper = styled.div`
  width: ${props => props.isArticle ? 'calc(100% + 32vw)' : 'calc(100% + 16vw)'};
  max-width: none;
  height: auto;
  margin: ${props => props.isArticle ? '-6vw 0px 50px calc(-16vw)' : '50px 0px 50px calc(-8vw)'};
  position: relative;
  overflow: hidden;
  @media (min-width: 724px){
    width: calc(100% + 16vw);
    margin: ${props => props.isArticle ? '-6vw 0px 50px calc(-8vw)' : '50px 0px 50px calc(-8vw)'};
  }
`

export const CloseBtn = styled(Link)`
  display: block;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 30px;
  border-radius: 50px;
  background: rgba(0,0,0,.15);
  border: 2px solid rgba(255,255,255,.5);
  z-index: 10;
  cursor: pointer;
  transition: background .3s,color .3s,border .3s;
  &::before, &::after{
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0) rotate(45deg);
    width: 65%;
    height: 2px;
    background: #fff;
    border-radius: 25px;
    transition: background .3s;
  }
  &::after{
    transform: translate3d(-50%,-50%,0) rotate(-45deg);
  }
  &:hover{
    background: #fff;
    color: #000;
    border-color: rgba(255,255,255,1);
    &::after {
      background: #000;
    }
    &::before{
      background: #000;
    }
  }
`

export const components = {
  h1: H1,
  h2: H2,
  p: P,
  strong: Strong,
  a: A,
  ul: UL,
}