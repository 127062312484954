import styled from 'styled-components'
import { device } from '../../utils/breakpoints'

export const HeaderWrapper = styled.header`
  padding: 20px;
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
  position: relative;
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > a{
      display: none;
    }
    @media (min-width: 864px){
      > a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0;
        > div:first-child {
          position: relative;
          width: 44px;
          height: 44px;
          margin-right: 12px;
          border-radius: 50%;
          overflow: hidden;
        }
        > div:last-child {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
  @media ${device.laptopL}{
    padding-left: 80px;
    padding-right: 80px;
  }
`