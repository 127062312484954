import React from 'react'

import { MobileToggle } from './styles'

export default function ToggleMenu({menuShowed, setMenuShowed}){
  const handleClick = () => {
    setMenuShowed(!menuShowed)
  }
  return (
    <MobileToggle 
      menuOpened={menuShowed} 
      onClick={handleClick}
    >
      <i />
    </MobileToggle>
  )
}
