import React from 'react'
import VideoMP4 from '../../video/hero-final.mp4'
import VideoWEBM from '../../video/hero-final.webm'

import { BackgroundVideoWrapper  } from './styles'

const BackgroundVideo = () => {
  return (
    <BackgroundVideoWrapper>
      <video autoPlay muted loop>
        <source src={VideoMP4} type="video/mp4" data-wf-ignore="true" />
        <source src={VideoWEBM} type="video/webm" data-wf-ignore="true" />
      </video>
    </BackgroundVideoWrapper>
  )
}

export default BackgroundVideo