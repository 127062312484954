import styled from 'styled-components'

export const StackWrapper = styled.section`
  padding-top: 8rem;
  text-align: center;
  .eyebrow{
    font-weight: 600;
    font-size: .8rem;
    text-transform: uppercase;
    letter-spacing: .1em;
    opacity: 0.7; 
  }
  .stack-section-title,
  .stack-section-message{
    max-width: 600px;
    margin: 0 auto;
  }
  .stack-section-title {
    font-size: 1.4rem;
  }
  .stack-section-message {
    margin-top: 20px;
    p{
      text-align: center;
      opacity: .85;
      letter-spacing: .5px;
      font-size: 16px;
      line-height: 1.5em;
    }
  }
  @media(min-width: 600px){
    .stack-section-title {
      font-size: 2rem;
    }
  }
`

export const StackBlockGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem 0;
  @media (min-width: 620px){
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 0.4fr;
    grid-template-areas:
      ". . ."
      ". center .";
  }
`


export const StackBlock = styled.div`
  text-align: center;
  border-radius: 4px;
  padding-bottom: 1.55rem;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  > div {
    border-radius: 4px;
    padding: 1.55rem 1.55rem 0;
    > h3{
      background: linear-gradient(to right,var(--highlight-color-1),var(--highlight-color-2));
      color: var(--text-color);
      text-decoration: none;
      font-weight: 600;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: .1em;
      font-weight: bold;
      margin-bottom: 1.55rem;
    }
  }
  &:hover {
    border-left: 2px solid var(--highlight-color-1);
    border-right: 2px solid var(--highlight-color-2);
    background-image: linear-gradient(to right,var(--highlight-color-1),var(--highlight-color-2)),linear-gradient(to right,var(--highlight-color-1),var(--highlight-color-2));
    background-size: 100% 2px;
    background-position: 0 0,0 100%;
    background-repeat: no-repeat;
    background-color: rgba(255,255,255,.04);
  }
  @media (min-width: 620px){
    &:last-child{
      grid-area: center; 
    }
  }
`

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  > a {
    display: inline-flex;
    &:not(:last-child){
      margin-right: 14px;
    }
    width: 33.33%;
    > svg {
      opacity: 0.5;
      width: 100%;
      &:hover {
        opacity: 1;
      }
    }
  }
  @media (min-width: 620px){
    flex-direction: column;
    > a {
      width: 66.66%;
      &:not(:last-child){
        margin-bottom: 0;
        margin-bottom: 24px;
      }
    }
  }
  @media (min-width: 768px){
    > a {
      width: 50%;
    }
  }
  @media (min-width: 990px){
    flex-direction: row;
    > a {
      display: inline-flex;
      &:not(:last-child){
        margin-bottom: 0;
        margin-right: 14px;
      }
      width: 33.33%;
    }
  }
`