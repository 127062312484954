import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Project Overview`}</h1>
    <p>{`Desarrollo de la nueva versión del software del Registro Genealógico de la FENAGH, utilizando `}<strong parentName="p">{`FaunaDB`}</strong>{` como base de datos y `}<strong parentName="p">{`NextJS`}</strong>{` como framework de desarrollo. `}</p>
    <h2>{`Que es Fauna?`}</h2>
    <p><a parentName="p" {...{
        "href": "https://fauna.com/"
      }}>{`Fauna`}</a>{` es una base de datos Global tipo serverless, iniciada por los antiguos ingenieros de Twitter Evan Weaver y Matt Freels, es una excelente base de datos creada para funcionar como una API de datos, su funcionalidad hace que sea ideal para trabajar con frameworks como NextJS.`}</p>
    <h2>{`Porque Fauna?`}</h2>
    <p>{`El modelo de almacenamiento de Fauna es de una base de datos no estructurada, la flexibilidad de dicha base de datos permite que el diseño, la funcionalidad y el modelo de negocios también sean flexibles.
Incluso con un base de datos no estructurada, es fácil configurar índices que funcionen como búsquedas sin tener que traer un gran conjunto de datos, lo que ahorra mucho ancho de banda y mejora el rendimiento de la aplicación.`}</p>
    <p>{`Además Fauna tiene la opción de crear nuestras propias funciones definidas (UDF), estas funciones pueden ser útiles para mejorar aún más el rendimiento de la aplicación, manejar algunas tareas localmente, redir las consultas API desde sus servidores, o ayudar a administrar acciones de manera más consistente.`}</p>
    <h2>{`Montando la app con Next.js`}</h2>
    <p><a parentName="p" {...{
        "href": "https://nextjs.org/"
      }}>{`NextJS`}</a>{` me ofrece una experiencia de desarrollo con todas las funciones que se necesitan para llevar a producción cualquier proyecto (como su propia web lo indica): renderizado híbrido entre `}<strong parentName="p">{`estático`}</strong>{` o de `}<strong parentName="p">{`servidor`}</strong>{`, compatibildiad con TypeScript, smart bundling, pre-fetching de rutas, optimización de imagenes y análiticas, entre muchas otras más.`}</p>
    <p>{`El proyecto se encuentra en una face muy avanzada de desarrollo, próximamente publicare más avances.`}</p>
    <br />
    <br />
    <center><bold>Work In Progress</bold></center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      