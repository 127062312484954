import styled from 'styled-components'
import { device } from '../../utils/breakpoints'

export const PostWrapper = styled.div`
  display: inline-block;
  border-radius: 3px;
  overflow: hidden;
  margin: 0;
  margin-bottom: 30px;
  width: 100%;
  height: auto;
  > div {
    position: relative;
    z-index: 2;
    .link-container {
      position: relative;
      display: block;
      color: var(--white);
      text-decoration: none;
      > div:first-child{
        width: 100%;
        height: 100%;
        position: relative;
        background: rgba(0,0,0,.5);
        overflow: hidden;
        border-bottom-left-radius: ${props => props.externalLink ? '0' : '3px'};
        border-bottom-right-radius: ${props => props.externalLink ? '0' : '3px'};
        .gatsby-image-wrapper-constrained{
          display: block;
        }
      }
      > div:last-child{
        padding: ${props => props.externalLink ? '20px' : '20px 0 0'} ;
        background-color: ${props => props.externalLink ? 'var(--post-bg-color)' : 'transparent'} ;
        h3{
          font-size: 1.5rem;
          line-height: 1.25;
          font-weight: 500;
          margin-bottom: 10px;
          > svg {
            display: inline-flex;
            width: 15px;
            margin-left: 10px;
          }
        }
        h3 + div {
          opacity: .5;
          line-height: 1.6;
          margin-top: 15px;
          p {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
  @media ${device.tablet}{
    margin: 0 20px;
    margin-bottom: 2.5rem;
    width: calc(50% - 40px);
  }
  @media ${device.laptop}{
    width: calc(33.3333% - 40px);
  }
`