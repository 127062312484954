import styled from 'styled-components'

export const BackgroundHeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -2;
  transition: filter .5s,opacity 3s;
  &:before {
    background-image: radial-gradient(
      rgba(0,0,0,.0),
      var(--gradient-overlay)
    );
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }
  &:after {
    background: linear-gradient(
      180deg
      ,rgba(0,0,0,.0) 0%,var(--gradient-overlay) 100%
    );
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    z-index: -1;
  }
  .image-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    &:first-child{
      filter: ${props => props.isOpacity ? 'blur(3px)' : 'blur(0px)'};
      opacity: ${props => props.isOpacity ? '.08' : '1'};
      transition: filter .5s,opacity 3s;
    }
    .gatsby-image-wrapper{
      width: 100%;
      height: 100vh;
    }
  }
  @media (min-width: 520px){
    .image-wrapper .gatsby-image-wrapper{
      max-width: 50%;
      margin-left: 50%;
    }
  }
  @media (min-width: 990px){
    display: none;
  }
`