import styled from 'styled-components'
import VideoBackggroundImage from '../../images/hero-final-poster-00001.jpg'

export const BackgroundVideoWrapper = styled.div`
  display: none;
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 1046px;
  height: 1082px;
  z-index: -1;
  video {
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    object-fit: cover;
    z-index: -100;
    background-image: ${`url(${VideoBackggroundImage})`};
    background-size: cover;
    background-position: 50% 50%;
  }
  @media (min-width: 990px){
    display: block;
  }
`